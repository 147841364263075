<template>
    <DelireComponent />
</template>

<script>
// @ is an alias to /src
import DelireComponent from '@/components/DelireComponent.vue'

export default {
  name: 'delireView',
  components: {
    DelireComponent
  }
}
</script>
