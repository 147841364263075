<template>
  <v-app>
    <v-toolbar elevation="4" max-height="60">
      <v-toolbar-title class="headline text-uppercase">
        <span>gne</span>
        <span class="font-weight-light">gne</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-tooltip left color="primary">
          <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on" text>
          <router-link to="/">gnegne</router-link>
        </v-btn>
      </template>
      <span>gnegne</span>
      </v-tooltip>
      </v-toolbar-items>
    </v-toolbar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: "App",
  components: {
  },
  data() {
    return {
      //
    };
  }
};
</script>