<template>
  <v-data-table
    :headers="headers"
    :items="gyms"
    :items-per-page="5"
    class="elevation-1"
  >
  <template v-slot:[`item.percent`]="{ item }">
          {{ formatToPercent(item.percent) }}
          <v-progress-linear :value="item.percent" :height="25"></v-progress-linear>
    </template>

</v-data-table>
</template>

<script>

import axios from 'axios'
export default {
  name: 'DelireComponent',

  methods: {
    formatToPercent(value) {
      value = Math.round(value * 100) / 100
      return value + " %"
    }
  },
  mounted() {
    for (const gym of this.gyms) {
    axios.get('https://www.delirescalade.com/web/wp-json/api/occupancy?skipcache=1&code=' + gym.id, {
    })
      .then(response => {
        gym.count = response.data.count
        gym.percent = response.data.percent
      })
      .catch(error => {
        console.log(error)
      })
      console.log()
    }  
},

data () {
      return {
        gyms: [
          {
            name: "Sainte Foy",
            id: 'DLE',
            count : 0,
            percent : 0.0
          },
          {
            name: 'Pierre Bertrand',
            id: 'DPB',
            count : 0,
            percent : 0.0
          },
          {
            name: 'Beauport',
            id: 'BPT',
            count : 0,
            percent : 0.0
          },
          {
            name: 'Lévis',
            id: 'DEL',
            count : 0,
            percent : 0.0
          },
          {
            name: 'Parc',
            id: 'LCE',
            count : 0,
            percent : 0.0
          },
        ],
        headers: [
          {
            text: 'Emplacement',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Quantité', value: 'count' },
          { text: 'Pourcentage', value: 'percent' },
        ],
      }
    },
}
</script>
