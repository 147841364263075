<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="4">
        <v-text-field v-model="ip" label=ip readonly></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="7">
        <video-player :src="require('../assets/gnegne.mp4')" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { videoPlayer } from 'vue-md-player'
import axios from 'axios'
export default {
  components: {
    videoPlayer,
  },
  name: 'HelloWorld',

  mounted() {
    axios.get('https://ipinfo.io/json?token=3d903a7bd9ce36', {
    })
      .then(response => {
        this.ip = response.data.ip
        this.region = response.data.region
        this.country = response.data.country
        console.log(this.wholeResponse)
      })
      .catch(error => {
        console.log(error)
      })
  },

  data: () => ({
    ip: "loading...",
    country: "loading...",
    region: "loading...",
  }),
}
</script>
