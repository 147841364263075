import Vue from 'vue'
import VueRouter from 'vue-router'
import gnegne from '../views/gnegne.vue'
import DelireComponent from '../views/delire.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'gnegne',
    component: gnegne
  },
  {
    path: '/delire',
    name: 'delire',
    component: DelireComponent
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
