<template>
  <div class="home">
    <gnegne msg="Welcome to gnegne"/>
  </div>
  </template>

<script>
// @ is an alias to /src
import gnegne from '@/components/gnegne.vue'

export default {
  name: 'HomeView',
  components: {
    gnegne
  }
}
</script>
